
.listing-article {
    padding-left: 158px + 15px;
    color: $c-link;
    text-align: left;
    min-height: 100px;

    &:hover {
        color: $c-link--hover;
    }
}

    .listing-article--large-thumbnail {
        padding-left: 290px + 15px;
        min-height: 220px;
        margin-bottom: 30px;

        .listing-article__image {
            margin-left: -305px;
            width: 290px;
        }

        .listing-article__title {
            @include rem(18px);
        }

        @include breakpoint(mobile) {
            padding-left: 0;

            &:last-child {
                margin-bottom: 0;
            }

            .listing-article__image {
                float: none;
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                height: auto;
                margin-bottom: 15px;
            }
        }
    }

    .listing-article--homepage {
        padding: 0;
        min-height: none;

        .listing-article__image {
            float: none;
            margin: 0 0 30px;
            width: auto;
        }

        .listing-article__title {
            @include rem(22px);
        }

        .listing-article__intro {
            @include rem(20px);
        }
    }

    .listing-article--popular {
        margin-bottom: 15px;
    }

    .listing-article__image {
        float: left;
        margin-left: -158px;
        margin-right: 25px;

        @include breakpoint(mobile) {
            flex-basis: 80px;
            margin-right: 15px;
        }

        .listing-article--homepage & {
            flex: none;
            margin-right: 0;
            margin-bottom: 30px;
        }

        .listing-article--large-thumbnail & {
            flex-basis: 290px;

            @include breakpoint(mobile) {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .listing-article__title {
        @include linktitle;
        margin-bottom: 12px;
        overflow: hidden;

        @include breakpoint(mobile) {
            @include rem(14px);

            line-height: calc(17 / 14);
        }

        .listing-article--homepage & {
            @include rem(22px);
            text-transform: uppercase;
        }

        .listing-article--large-thumbnail & {
            @include rem(18px);

            text-transform: uppercase;
        }

        .listing-article:hover & {
            color: $c-link--hover;
        }
    }

    .listing-article__category {
        @include rem(16px);
        font-weight: normal;
        margin-bottom: 8px;
        color: $c-black;
    }
    .listing-article__intro {
        @include copy;
    }

    .featured-article__featured {
        text-align: left;
        float: left;
        padding-bottom: 0;
    }

    .featured-article__image {
        float: right;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .featured-article__content {
        padding-top: 0;
        text-align: left;
        float: left;
    }

    .featured-article__title {
        @include linktitle;
        margin-bottom: 15px;
    }

@include breakpoint (tablet) {
    .featured-article__image {
        padding-top: 0;
        margin-bottom: 20px;
    }
}

.blog__popular {
    padding-bottom: 0;
    overflow: hidden;
}

.blog__articles {
    .grid--left {
        padding-bottom: 25px;
    }
}

.popular-article {
    text-align: left;
}
    .popular-article__image {
        margin-bottom: 15px;
    }
    .popular-article__title {
        @include linktitle;
        margin-bottom: 15px;
    }

.article {

}
    .article__body {
        text-align: left;
    }
    .article__intro {
        margin-bottom: 40px;
    }
    .article__social {
        margin-top: 40px;
    }

    .article__download {
        margin-top: 30px;
        font-weight: 500;
        i {
            margin-right: 10px;
        }
        span {
            border-bottom: 1px solid $c-grey--mid;
        }
    }

.article--no-body {
    text-align: center;
}
    .article--no-body .slider__info {
        text-align: center;
    }
    .article--no-body .article__social {
        text-align: center;
        margin-bottom: 40px;
    }
.article--no-images {

}
    .article--no-images .article__social {
        text-align: center;
    }


    .article__nav {

    }
        .article__prev,
        .article__next {
            position: relative;
            display: block;
            padding-top: 30px;
            padding-bottom: 30px;

            i {
                position: absolute;
                top: 17px;
                font-size: 64px;
            }

            &:hover i {
                color: $c-link--hover;
            }
        }
        .article__prev {
            text-align: left;
            padding-left: 50px;

            i {
                left: 0
            }
        }
        .article__next {
            text-align: right;
            padding-right: 50px;

            i {
                right: 0;
            }
        }
        .article__nav__title {
            display: inline-block;
            margin-bottom: 10px;
            @include link;
            @include rem(14px);
            text-transform: uppercase;
        }
        .article__nav__category {
            @include f-light;
            @include rem(14px);
            display: block;
            margin: 0;
        }

.article__products {
    text-align: center;
}
    .article__products__title,
    .article__related__title {

    }


.article__related {
    text-align: left;
}

.article__related--new {
    padding-top: 15px;
    border-top: 1px solid $c-border-light;

    .article__related__title {
        @include font(18px, 22px);
        @include lightText;

        margin-bottom: 35px;
        text-transform: uppercase;
        color: $c-black;
        letter-spacing: 4px;

        @include breakpoint(tablet) {
            @include font(14px, 17px);

            margin-bottom: 25px;
            letter-spacing: 3px;
        }
    }
}

.blog-video {
    display: block;
    width: 100%;
    height: 390px;
    max-width: 640px;
    margin: 0 auto;
}
