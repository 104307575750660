// Driver calculator

.driver-calculator-form {}

    .driver-calculator-form__stage {
        @include rem(15px);

        position: relative;
        padding: 30px 0;
    }

        .driver-calculator-form__stage--recommended {
            padding-bottom: 10px;
        }

        .driver-calculator-form__stage--dimmer-type {
            padding-bottom: 300px;

            @include breakpoint(mobile) {
                padding-bottom: 30px;
            }
        }

        .driver-calculator-form__back {
            @include rem(16px);

            line-height: calc(24 / 16);

            position: absolute;
            top: 30px;
            left: 0;
            font-weight: 300;
            text-transform: uppercase;
            text-decoration: underline;

            .fa {
                margin-right: 5px;
            }

            @include breakpoint(tablet) {
                display: block;
                position: static;
                text-align: left;
                margin-bottom: 10px;
                margin-top: -20px;
            }
        }

        .driver-calculator-form__question {
            max-width: 570px;
            margin: 0 auto;

            .driver-calculator-form__question + & {
                margin-top: 20px;
            }
        }

            .driver-calculator-form__heading {
                @include rem(20px);
                line-height: calc(24 / 20);
                color: $blue1;
            }

                .driver-calculator-form__heading--large {
                    @include rem(25px);
                    line-height: calc(24 / 25);
                }

                .driver-calculator-form__heading--black {
                    color: $c-black;
                }

            .driver-calculator-form__inputs {
                padding: 30px 0 0;
                max-width: 240px;
                margin: 0 auto;

                @include breakpoint(mobile) {
                    max-width: none;
                }

                label {
                    margin-bottom: 10px;
                    display: block;
                    text-align: left;

                    @include breakpoint(mobile) {
                        text-align: center;
                    }
                }

                input {
                    max-width: 105px;
                    color: $grey2;
                    float: left;

                    @include breakpoint(mobile) {
                        max-width: none;
                        float: none;
                    }
                }
            }

            .driver-calculator-form__text {
                * + & {
                    margin-top: 10px;
                }

                p {
                    line-height: calc(20 / 16);

                    @include breakpoint(mobile) {

                        line-height: calc(22 / 16);
                    }
                }

                p + p {
                    margin-top: 10px;
                }

                a {
                    text-decoration: underline;
                    color: inherit;
                }
            }

            .driver-calculator-form__text--blue p {
                color: $blue1;
            }

            .driver-calculator-form__icon {
                margin: 10px auto 0;

                svg {
                    fill: $gold;
                }

                .icon-driver-icon {
                    width: 100%;
                    max-width: 260px;
                }

                span {
                    border: 1px solid $gold;
                    padding: 4px 8px;
                    color: $gold;
                    font-weight: 200;
                }
            }

            .driver-calculator-form__icon--large {
                margin-top: 25px;
            }

            .driver-calculator-form__icon--flush {
                margin-top: 0;
            }

            .driver-calculator-form__arrow {
                margin: 10px auto 0;

                svg {
                    stroke: $gold;
                }
            }

            .driver-calculator-form__arrow--flush {
                margin-top: 0;
            }

            .driver-calculator-form__options {

                * + & {
                    margin-top: 20px;
                }

                &:before {
                    content: '';
                    display: block;
                    position: relative;
                    left: 50%;
                    width: 1px;
                    height: 20px;
                    background: $gold;
                    margin-left: -1px;

                    @include breakpoint(mobile) {
                        display: none;
                    }
                }
            }

                .driver-calculator-form__option {
                    position: relative;
                    font-size: 0;

                    &:before {
                        content: '';
                        display: block;
                        position: relative;
                        left: 50%;
                        width: 1px;
                        height: 20px;
                        margin-bottom: 10px;
                        background: $gold;

                        @include breakpoint(mobile) {
                            display: none;
                        }
                    }

                    .driver-calculator-form__option + & {

                        @include breakpoint(mobile) {
                            margin-top: 10px;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: -50%;
                            width: 100%;
                            height: 1px;
                            background: $gold;

                            @include breakpoint(mobile) {
                                display: none;
                            }
                        }
                    }
                }

                .driver-calculator-form__options--has-tooltip .driver-calculator-form__option + .driver-calculator-form__option {

                    @include breakpoint(mobile) {
                        margin-top: 32px;
                    }
                }

                    .driver-calculator-form__radio {
                        display: none;
                    }

                    .driver-calculator-form__tooltip {
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 5px;
                        font-size: 0;
                        cursor: pointer;
                    }

                    .driver-calculator-form__options--has-tooltip .driver-calculator-form__tooltip {
                        @include breakpoint(mobile) {
                            position: relative;
                            top: 65px;
                        }
                    }

                        .driver-calculator-form__tooltip__icon {
                            display: inline-block;
                            vertical-align: middle;
                            width: 16px;
                            height: 16px;

                            @include breakpoint(mobile) {
                                width: 22px;
                                height: 22px;
                            }

                            svg {
                                fill: $c-brand;

                                .driver-calculator-form__radio:checked + .driver-calculator-form__tooltip & {
                                    fill: $blue1;
                                }
                            }
                        }

                        .driver-calculator-form__tooltip__text {
                            @include rem(14px);
                            line-height: calc(17 / 14);
                            vertical-align: middle;
                            margin-left: 10px;
                            text-decoration: underline;
                        }

                    .driver-calculator-form__label {
                        @include rem(20px);
                        line-height: calc(24 / 20);
                        font-weight: 300;
                        cursor: pointer;
                        text-decoration: underline;

                        &:hover {
                            color: $c-black;
                        }

                        @include breakpoint(mobile) {
                            @include rem(14px);
                            line-height: calc(17 / 14);
                            display: block;
                            padding: 14px 11px 11px;
                            text-transform: uppercase;
                            color: $c-white;
                            background: $c-grey--mid;
                            text-decoration: none;
                        }

                        .driver-calculator-form__radio:checked ~ & {
                            @include breakpoint(mobile) {
                                background: $c-brand;
                            }
                        }
                    }

                    .driver-calculator-form__options--has-tooltip .driver-calculator-form__label {

                        @include breakpoint(mobile) {
                            margin-top: -22px;
                            margin-bottom: 45px;
                        }
                    }

.driver-calculator-results {
    display: none;
}

.driver-calculator-results--show {
    display: block;
    padding-top: 70px;
    padding-bottom: 30px;
    background: $c-section-bg--alt;

    @include breakpoint(mobile) {
        padding-bottom: 20px;
    }
}

    .driver-calculator-results__driver {
        @extend .section;
        background: none;
        padding-bottom: 0;
        font-size: 0;

        @include breakpoint(mobile) {
            .container {
                padding: 0;
            }
        }
    }

        .driver-calculator-results__driver-inner {
            display: flex;
            border: 1px solid #d8d8d8;
            border-radius: 3px;
            position: relative;
            box-sizing: border-box;
            // Image + border
            min-height: 151px;
            justify-content: flex-end;
            align-items: flex-start;
            align-content: flex-start;
            padding: 15px;

            @include breakpoint(mobile) {
                display: block;
                padding: 0;
            }
        }

        .driver-calculator-results__driver__thumb {
            @include aspect(1);

            position: relative;
            width: 160px;
            flex: 0 0 auto;
            margin: -15px 15px -15px -15px;

            @include breakpoint(mobile) {
                position: static;
                display: block;
                width: 100%;
                height: auto;
                margin: 0 0 20px;
            }
        }

            .driver-calculator-results__driver__thumb__badge {
                @include rem(12px);
                box-sizing: border-box;
                width: 90%;
                display: none;
            }

            .driver-calculator-results__driver__thumb__image {

                img {
                    width: 100%;
                    height: auto;
                }
            }

        .driver-calculator-results__content {
            flex: 1 1 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            @include breakpoint(mobile) {
                display: block;
            }
        }

            .driver-calculator-results__heading {
                @include font(20px, 30px);
                flex: 0 0 100%;
                text-align: left;
                margin-bottom: 10px;

                a {
                    color: $c-black;
                }

                @include breakpoint(mobile) {
                    @include font(16px, 30px);
                    margin: 0 10px 10px;
                }
            }

            .driver-calculator-results__info-row {
                flex: 0 0 100%;
                display: flex;
                justify-content: flex-end;
                padding-bottom: 15px;

                @include breakpoint(mobile) {
                    flex-wrap: wrap;
                    margin: 0 10px;
                }

                &:last-child {
                    padding-bottom: 0;
                }

            }

            .driver-calculator-results__info-row + .driver-calculator-results__info-row {
                border-top: 1px solid #d8d8d8;
                padding-top: 15px;
            }

        .driver-calculator-results__copy {
            text-align: left;
            flex: 1 1 auto;
            display: flex;

            &--column {
                flex-direction: column;
            }

            @include breakpoint(mobile) {
                min-width: none;
                flex: 0 0 100%;
            }
        }

            .driver-calculator-results__code {
                @include font(16px, 19px);
                @include lightText;
                color: $c-body;
            }

            .driver-calculator-results__divide {
                @include font(16px, 19px);
                @include lightText;

                margin: 0 10px;
                color: $c-body;
            }

            .driver-calculator-results__min-max {
                @include font(12px, 15px);
                @include regularText;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $c-body;

                span {
                    @include boldText;
                }
            }

            .driver-calculator-results__text {
                text-align: left;

                * + & {
                    margin-top: 10px;
                }

                ul {
                    margin-left: 16px;
                    list-style-position: outside;
                }

                p, li {
                    margin: 0;
                    line-height: calc(19 / 16);
                    font-weight: 100;
                }

                p + p,
                li + li {
                    margin-top: 10px;
                }
            }

        .driver-calculator-results__actions {
            text-align: right;
            flex: 0 0 auto;

            @include breakpoint(mobile) {
                text-align: left;
                padding: 10px 0;
                flex: 0 0 100%;
            }
        }

            .driver-calculator-results__price {
                @include copy;
                @include f-regular;
                @include font(16px, 19px);
                display: inline-block;
                margin: 0;

                @include breakpoint(mobile) {
                    padding-left: 0;
                }
            }

            .driver-calculator-results__availabilty {
                @include rem(14px);
                display: inline-block;
                line-height: calc(17 / 14);
                font-weight: 300;

                @include breakpoint(mobile) {
                    position: relative;
                    line-height: calc(19 / 14);
                }
            }

            .driver-calculator-results__submit {
                display: inline-block;
                text-align: right;

                @include breakpoint(mobile) {
                    width: 100%;
                }
            }

            .driver-calculator-results__form {
                display: inline-block;
                margin-left: 20px;

                @include breakpoint(mobile) {
                    text-align: left;
                    display: block;
                    margin-left: 0;
                }

                .button {
                    @include breakpoint(mobile) {
                        width: 100%;
                    }
                }
            }

            .driver-calculator-results__qty {
                max-width: 150px;

                @include breakpoint(mobile) {
                    max-width: none;
                }
            }

            .driver-calculator-results__recommended {
                @include rem(14px);
                line-height: calc(17 / 14);
                font-weight: 300;
                font-style: italic;
                display: inline-block;
                vertical-align: bottom;

                @include breakpoint(mobile) {
                    @include rem(16px);
                    line-height: calc(19 / 16);
                }

                .driver-calculator-results__actions--purchase & {
                    @include breakpoint(mobile) {
                        padding-top: 25px;
                    }
                }
            }

            .driver-calculator-results__browse {
                display: inline-block;
                max-width: 100px;
                vertical-align: bottom;

                @include breakpoint(mobile) {
                    max-width: none;
                    display: block;
                    margin-bottom: 15px;
                }

                .driver-calculator-results__actions--browser & {
                    display: block;
                    margin-bottom: 5px;
                }
            }

    .driver-calculator-results__toggle {
        text-align: center;

        * + & {
            margin-top: 20px;
        }

        [data-multiple-drivers="true"] ~ .driver-calculator-results & {
            display: block;
        }

        [data-multiple-drivers="false"] ~ .driver-calculator-results & {
            display: none;
        }
    }

        .driver-calculator-results__toggle__option {
            border-bottom: 1px solid $c-black;
            cursor: pointer;
            display: none;

            span {
                display: inline-block;
                vertical-align: middle;
            }
        }

            .driver-calculator-results__toggle__text {
                @include rem(14px);

                line-height: 1;

                .fa {
                    margin-right: 10px;
                }
            }

        .driver-calculator-results[data-other-open="true"] {
            .driver-calculator-results__toggle__option--collapse {
                display: inline-block;
            }
        }

        .driver-calculator-results[data-other-open="false"] {
            .driver-calculator-results__toggle__option--expand {
                display: inline-block;
            }
        }

    .driver-calculator-other-results-wrap {
        display: none;
    }

    .driver-calculator-results[data-other-open="true"] {
        .driver-calculator-other-results-wrap {
            display: block;
        }
    }

    .driver-calculator-recommended-results-wrap {
        > .driver-calculator-results__driver {
            padding-top: 0;
        }

        .driver-calculator-results__driver__thumb__badge {
            display: block;
        }

        .driver-calculator-results__driver-inner {
            border: 2px solid #DDDDDD;
        }
    }

    .driver-calculator-other-dimming-divider {
        display: none;
    }

    .driver-calculator-other-dimming-divider + .driver-calculator-results {
        padding-top: 30px;
    }

.driver-calculator-results__loading {
    @include rem(16px);
    display: none;
    padding: 20px;
    text-align: center;
}

    .driver-calculator-results__loading__image {
        margin: 0 auto 20px;
        max-width: 54px;
    }

    .driver-calculator-results__loading__message {
        color: $c-grey--mid;
    }

.driver-calculator-results__error {
    display: none;
    padding: 20px;
    text-align: center;
}

    .driver-calculator-results__error__icon {
        margin: 0 auto 20px;

        svg {
            fill: $c-error;
        }
    }

    .driver-calculator-results__error__message {
        @include rem(16px);
        @include lightText;
        color: $c-error;
        max-width: 460px;
        margin: 0 auto;
        line-height: 1.5;
    }
