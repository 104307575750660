.large-callout {
    padding: 80px 80px 0 80px;
    background: $c-grey--button;

    @include breakpoint(tablet) {
        padding: 40px 20px 0 20px;
    }

    &:last-of-type {
        padding: 80px;

        @include breakpoint(tablet) {
            padding: 40px 20px;
        }
    }
}

    .large-callout__image {
        @include aspect(3 / 2);

        position: relative;

        @include breakpoint(tablet) {
            margin-bottom: 30px;
        }

        img {
            @include cover;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .large-callout__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        max-width: 580px;
        padding-left: 80px;

        @include breakpoint(tablet) {
            padding-left: 0;
        }
    }

        .large-callout__title {
            @include font(40px, 46px);

            margin-bottom: 15px;
            color: $c-black;

            @include breakpoint(tablet) {
                @include font(30px, 34px);
            }

            span {
                @include thinText;
            }
        }

        .large-callout__text {
            max-width: 100%;
        }
