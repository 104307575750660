.sliding-accordion {}

    .sliding-accordion__list {

    }

    .sliding-accordion__list--active {

        > .sliding-accordion__item:not(.sliding-accordion__item--active) {
            display: none;
        }
    }

        .sliding-accordion__item {
            position: relative;
        }

        .sliding-accordion__item--block {
            display: block;
        }

            .sliding-accordion__button {
                @include lightondark;
                @include remove-white-space;

                width: 100%;
                display: inline-block;
                line-height: 1.3;
                padding: 0;

                outline: none;
                cursor: pointer;
                text-decoration: none;

                margin: 0;
                background-color: transparent;
                border-left: 1px solid $c-border;
                border-top: 1px solid $c-border;
                border-right: 1px solid $c-border;
                -webkit-font-smoothing: antialiased; /*make it look nice in webkit*/
                transition: background-color .3s ease, border-color .3s ease, color .3s ease;
                white-space: nowrap;

                min-height: 62px;
                .sliding-accordion__item--first > & {
                    border-radius: 3px 3px 0 0;
                }

                .sliding-accordion__item--last:not(.sliding-accordion__item--active) > & {
                    border-bottom: 1px solid $c-border;
                    border-radius: 0 0 3px 3px;
                }

                a:hover &,
                &:hover {

                    &:not(.button--no-hover) {
                        color: $c-white;
                        background-color: $c-black;
                        border-color: $c-black;
                    }

                    + .sliding-accordion__label {
                        color: $c-white;
                    }
                }

                &:after {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 0px;
                    height: 43px;
                }

                > span {
                    @include apply-map($f-button);
                    @include font(18px, 21px);

                    padding: 20px;
                    vertical-align: middle;
                    white-space: normal;
                    pointer-events: none;
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    @include breakpoint(tablet) {
                        @include font(16px, 21px);
                    }
                }

                .sliding-accordion__item--child-active > & {
                    display: none;
                }

                svg {
                    transition: fill .3s ease;
                    margin-left: 20px;
                    fill: currentColor;

                    .sliding-accordion__item--active > & {
                        transform: rotate(180deg);
                        margin-left: 0;
                    }
                }
            }

                .sliding-accordion__button-label {

                    .sliding-accordion__item--active > .sliding-accordion__button & {
                        display: none;
                    }
                }

            .sliding-accordion__label {
                display: none;

                .sliding-accordion__item--active > & {
                    @include apply-map($f-button);
                    @include font(18px, 21px);

                    display: block;
                    position: absolute;
                    top: 32px;
                    left: 50%;
                    max-width: calc(100% - 84px);
                    transform: translate(-50%, -50%);
                    text-align: center;
                    text-transform: none;
                    letter-spacing: normal;

                    @include breakpoint(tablet) {
                        @include font(16px, 21px);
                    }
                }

                .sliding-accordion__item--child-active > & {
                    display: none;
                }
            }

            .sliding-accordion__content {
                display: none;

                .sliding-accordion__item--active & {
                    display: block;
                }

                .sliding-accordion__content & {
                    display: none;
                }
            }

            .sliding-accordion__content--last {

                .sliding-accordion__item--active > & {
                    display: block;
                }
            }