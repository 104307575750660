// Product listings

.listing-item {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px solid $c-grey--button;

    @include breakpoint(tablet) {
        margin: 0;
        padding: 20px;
    }
}

.listing-item__figure {
    position: relative;
    z-index: 1;
    background: $background-grey;
}

    .listing-item__figure--rollover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        transition: opacity 0.2s $transition-function;
    }

    .listing-item__figure--rollover--hidden {
        opacity: 0;
    }

.listing-item__anchor {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,0.4);
        opacity: 0;
        transition: opacity 0.2s $transition-function;
    }

    &:hover:before {
        opacity: 1;
    }
}

.listing-item__title {
    @include font(14px, 22px);

    color: $c-black;

    @include breakpoint(tablet) {
        @include font(20px, 30px);

        margin-top: 5px;
    }
}

.listing-item__badge {
    display: block;
    position: absolute;
    top: 10px;
    left: -28px;
    background: black;
    @include rem(14px);
    color: white;
    text-transform: uppercase;
    text-align: center;
    padding: 2px 10px;
    z-index: 1;
    width: 100px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

    .listing-item__badge--red {
        background: $c-error;
    }

    .listing-item__badge--green {
        background: $c-success;
    }

    .listing-item__badge--small {
        @include rem(10px);
    }

    .listing-item__badge--centered {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }

    .listing-item__badge--right {
        right: 10px;
        left: auto;
    }

.listing-item__overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    background: linear-gradient(180deg, rgba(255,255,255,0.29) 0%, #000000 100%);
    color: $c-white;

    .listing-item:hover & {
        display: block;

        @include breakpoint(tablet) {
            display: none;
        }
    }
}

.listing-item__overlay__title {
    @include rem(16px);
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    line-height: 1.5;
    text-align: left;
}

.listing-item__overlay__title__price {
    display: block;
    color: $c-white;
    @include f-light;
}

.listing-item__overlay__details {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

.listing-item__overlay__details__stock {
    text-align: left;
    @include f-light;
    line-height: 1.5;
    margin-bottom: 4px;
    @include rem(15px);
}

// Fix a specific issue on windows
.item__overlay__details__stock__note {
    @media screen and (max-width: 1150px) {
        display: none;
    }
}

.listing-item__overlay__details__button {
    margin-top: 10px;
}

.listing-item--featured {
    .listing-item__figure {
        img,
        &:before {
            margin-bottom: -0.5px;
        }
    }

    .section-title {
        margin: 50px 0 0;
    }

    .listing-item__desc {
        display: inline-block;
        margin-top: 30px;
        color: white;
        border-bottom: 1px solid white;
    }

    .button {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.listing-item__preview {
    flex: 0 0 100px;

    @include breakpoint(tablet) {
        flex: 0 0 100%;
        display: flex;
        margin-bottom: 10px;
    }
}

    .listing-item__info {
        margin-bottom: 10px;

        @include breakpoint(tablet) {
            margin: 0 20px 0 0;
        }
    }

        .listing-item__thumbnail {
            @include aspect(1);

            position: relative;
            width: 100px;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }
        }

.listing-item__options {
    flex: 1 1 1px;
    margin: 0 0 0 15px;

    @include breakpoint(tablet) {
        margin-right: 10px;
        margin-left: 0;
    }

    @include breakpoint(phablet) {
        margin-right: 0;
    }
}

[data-property="code"] {
    width: 6.5%;

    @include breakpoint(phablet) {
        width: 25%;
    }
}

[data-property="finish"] {
    width: 6.5%;
    text-align: center;

    .swatch {
        margin: 0 auto;
    }

    @include breakpoint(phablet) {
        width: 25%;
    }
}

[data-property="wattage"] {
    width: 12.5%;

    @include breakpoint(phablet) {
        width: 50%;
    }

    .listing-item--logged-in &,
    .listing-item__table-head--logged-in & {
        width: 12.5%;

        @include breakpoint(phablet) {
            width: 25%;
        }
    }
}

[data-property="wattage"][data-property="wattage"] {
    @include breakpoint(phablet) {
        white-space: initial;
    }
}

[data-property="k"],
[data-property="loc"] {
    width: 6%;

    @include breakpoint(phablet) {
        width: 25%;
    }

    .listing-item--logged-in &,
    .listing-item__table-head--logged-in & {
        width: 6%;

        @include breakpoint(phablet) {
            width: 25%;
        }
    }
}

[data-property="lm"],
[data-property="power-connection"],
[data-property="dimmer"] {
    width: 11.5%;

    @include breakpoint(phablet) {
        width: 25%;
    }

    .listing-item--logged-in &,
    .listing-item__table-head--logged-in & {
        width: 10.5%;

        @include breakpoint(phablet) {
            width: 25%;
        }
    }
}

//[data-property="loc"] {
//    width: 7.5%;
//
//    @include breakpoint(phablet) {
//        width: 12.5%;
//    }
//
//    .listing-item--logged-in &,
//    .listing-item__table-head--logged-in & {
//        width: 7.5%;
//
//        @include breakpoint(phablet) {
//            width: 15%;
//        }
//    }
//}

[data-property="cri"],
[data-property="switched"],
[data-property="fire-rated"],
[data-property="beam-angle"],
[data-property="adjustable"],
[data-property="made-to-order"],
[data-property="device-charging"] {
    width: 4%;
    text-align: center;

    @include breakpoint(phablet) {
        width: 12.5%;
    }
}

[data-property="switched"],
[data-property="adjustable"] {
    .listing-item__table-head--show-project & {
        text-align: right; 
    }
}

[data-property="cri"]  {
    text-align: left;
}

[data-property="price"]  {
    width: 5%;

    .listing-item__table-head--show-project & {
        text-align: left;
    }
}

[data-property="price-mobile"]  {
    width: 25%;
}

[data-property="add-to-project"] {
    text-align: center;
    width: 10%;

    .listing-item__table-head--show-project & {
        width: unset;
    }

    @include breakpoint(phablet) {
        width: 25%;
    }
}

[data-property="add-to-project"][data-property="add-to-project"] {
    @include breakpoint(phablet) {
        white-space: initial;
        text-align: center;
    }
}

    .listing-item__table {
        width: 100%;

            th,
            td {
                padding-right: 3px;
                padding-left: 3px;
                vertical-align: middle;
                text-align: left;

                @include breakpoint(phablet) {
                    display: none;
                }

                &[data-property="price-mobile"] {
                    display: none;
                }

                &[data-property="fire-rated"],
                &[data-property="beam-angle"],
                &[data-property="dimmable"],
                &[data-property="switched"],
                &[data-property="adjustable"],
                &[data-property="device-charging"],
                &[data-property="finish"],
                &[data-property="made-to-order"] {
                    text-align: center;
                }

                &[data-property="code"],
                &[data-property="price-mobile"],
                &[data-property="finish"],
                &[data-property="wattage"],
                &[data-property="add-to-project"] {

                    [listing-technical-view="key-features"] & {

                        @include breakpoint(phablet) {
                            display: table-cell;
                        }
                    }
                }

                &[data-property="code"],
                &[data-property="k"],
                &[data-property="cri"],
                &[data-property="lm"],
                &[data-property="add-to-project"] {

                    [listing-technical-view="light-output"] & {

                        @include breakpoint(phablet) {
                            display: table-cell;
                        }
                    }
                }

                &[data-property="code"],
                &[data-property="loc"],
                &[data-property="fire-rated"],
                &[data-property="beam-angle"],
                &[data-property="dimmable"],
                &[data-property="switched"],
                &[data-property="adjustable"] {

                    [listing-technical-view="safety-control-features"] & {

                        @include breakpoint(phablet) {
                            display: table-cell;
                        }
                    }
                }

                svg {
                    display: block;
                    margin: 0 auto;
                    outline: none;
                }
            }

            th {
                @include font(12px, 20px);

                text-transform: uppercase;
                letter-spacing: 1px;
                color: $c-listing-table-header;
                white-space: nowrap;

                @include breakpoint(tablet) {
                    padding-bottom: 20px;
                }

                svg {
                    width: 25px;
                    height: 25px;
                }
            }

            td {
                @include font(13px, 15px);
                @include lightText;

                padding-top: 15px;
                padding-bottom: 15px;
                color: $c-listing-table-cell;
                word-wrap: anywhere;

                @include breakpoint(tablet) {
                    @include font(12px, 15px);
                }

                &[data-property="finish"] {
                    padding-top: 0;
                    padding-bottom: 5px;
                }

                svg {
                    width: 17px;
                    height: 14px;
                }
            }
    }

        .listing-item__table-head {
            display: none;

            @include breakpoint(tablet) {
                display: table-row;
            }
        }

        .listing-item__table-head--sticky {
            @include allowForBars((
                default: $header-height,
            ), top);

            display: none;

            [data-property="code"] {
                padding-left: 14px;
            }

            [data-listing-type="technical"] & {
                position: relative;
                display: flex;
                align-items: center;
                position: sticky;
                padding-top: 20px;
                padding-right: 30px + 92px;
                padding-bottom: 15px;
                padding-left: 30px + 100px + 15px;
                background: $c-white;
                z-index: 2;

                &::after {
                    content: '';
                    position: absolute;
                    left: 30px;
                    right: 30px;
                    bottom: 0px;
                    height: 1px;
                    background: $c-border-light;
                }
            }

            &.listing-item__table-head--logged-in {
                [data-listing-type="technical"] & {
                    padding-right: 98px;

                    @include breakpoint (wrap) {
                        padding-right: 108px;
                    }
                }

                &.listing-item__table-head--show-project {
                    [data-listing-type="technical"] & {
                        padding-right: 30px;
                    }
                }
            }

            > * {
                @include font(12px, 16px);

                padding-right: 3px;
                padding-left: 3px;
                text-transform: uppercase;
                letter-spacing: 0.5;
                color: $c-listing-table-header;
                vertical-align: middle;

                @include breakpoint(tablet) {
                    padding-bottom: 20px;
                }

                &[data-property="product"] {
                    position: absolute;
                    top: calc(50% + 2.5px); // Account for padding inbalance
                    transform: translateY(-50%);
                    left: 30px;
                }

                svg {
                    display: block;
                    width: 25px;
                    height: 25px;
                    margin: 0 auto;
                    outline: none;
                }
            }
        }

.listing-item__actions {
    flex: 0 0 94px;
    display: flex;
    flex-direction: column;

    .listing-item__table-head--show-project & {
        display: none;
    }

    @include breakpoint(tablet) {
        flex: 0;
        margin-top: 45px;
    }
}

    .listing-item__action {
        padding: 10px 0;
        text-align: right;
    }

    [data-listing-type="decorative"] {
        [data-listing-switch="decorative"] {
            fill: $grey2;
            cursor: default;

            &:hover {
                fill: $c-grey--mid;
            }
        }
    }

    [data-listing-type="technical"] {
        [data-listing-switch="technical"] {
            fill: $grey2;
            cursor: default;

            &:hover {
                fill: $c-grey--mid;
            }
        }
    }

.information-bar {
    background: $c-brand;
    color: $c-white;
    text-align: center;
    @include f-light;
    @include rem(14px);
    position: relative;
    padding: 12px;
    margin-bottom: 20px;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%);
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-top: 8px solid $c-brand;
    }

    @include breakpoint(tablet) {
        line-height: 18px;
    }
}

.collection-gallery .slider {
    @include aspect(1070 / 1550);
}



.table-separator {
    text-align: center;
    position: relative;
    height: 41px;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        border-bottom: 1px solid $grey2;
        z-index: 1;
    }
}

    .table-separator--dark {
        &:before {
            border-color: $c-black;
        }

        .table-separator__text {
            background: $background-grey;
            color: $c-black;
        }
    }

    .table-separator--blank {
        &:before {
            content: none;
        }
    }

    .table-separator__text {
        @include f-regular;
        color: $grey2;
        display: inline-block;
        background-color: $c-white;
        position: relative;
        z-index: 2;
        padding: 0 20px;
        line-height: 41px;
    }

    .listing-item__table__data-row {
        cursor: pointer;

        > td:first-child {
            position: relative;
            padding-left: 14px;

            &::before {
                top: 0;
                left: 0;
                width: 3px;
                height: 100%;

                position: absolute;
                content: "\00a0";
                border-radius: 3px 0 0 3px;
            }
        }

        &.listing-item__table__data-row--active {
            > td {
                background: #F5F5F5;

                &:first-child {
                    border-radius: 3px 0 0 3px;
                }

                &:last-child {
                    border-radius: 0 3px 3px 0;
                }
            }
        }
    }
