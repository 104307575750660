////
/// Responsive grid
///
/// @author Dom Morgan <dom@d3r.com>

// This is done in the sizings partial now.
// @import '../../../../core/resources/sass/grid';

/// Should the gutter defaults switch by breakpoint or
/// it be done here?
/// @author Dom Morgan <dom@d3r.com>
@mixin grid-wrapper {
    @include grid-gutter;
    @include remove-white-space;
    display: block;
}

@mixin grid-item {
    display: inline-block;
    vertical-align: top;
}

.grid {
    @include grid-wrapper;

    > * {
        @include grid-item;
    }
}

.grid--no-overflow {
    overflow: hidden;
}

.grid--flush-bottom {
    // Appropriate negative margins applied via grid-gutter mixin
    overflow: hidden;
}

// Flow items right to left
.grid--reverse {
    direction: rtl;

    > * {
        direction: ltr;
    }
}

.grid--left {
    text-align: left;
}
.grid--center {
    text-align: center;
}
.grid--right {
    text-align: right;
}

// Vertically top
.grid--top {
    > * {
        vertical-align: top;
    }
}
// Vertically centered
.grid--middle {
    > * {
        vertical-align: middle;
    }
}
// Vertically bottom
.grid--bottom {
    > * {
        vertical-align: bottom;
    }
}

.grid--tight {
    @include grid-gutter($grid-gutter-h--tight, $grid-gutter-v--tight);
}
.grid--semi-tight {
    @include grid-gutter($grid-gutter-h--semi-tight, $grid-gutter-v--semi-tight);
}
.grid--loose {
    @include grid-gutter($grid-gutter-h--loose, $grid-gutter-v--loose);
}
.grid--extra-loose {
    @include grid-gutter($grid-gutter-h--extra-loose, $grid-gutter-v--extra-loose);
}
.grid--responsive {
    
    @include breakpoint(tablet) {
        @include grid-gutter($grid-gutter-h--tight, $grid-gutter-v--tight);
    }
}
.grid--v-med {
    @include grid-gutter-vertical(20px);
}
.grid--h-med {
    @include grid-gutter-horizontal(20px);
}
.grid--flush {
    @include grid-gutter(0, 0);
}
.grid--m-flush {

    @include breakpoint(tablet) {
        @include grid-gutter(0, 0);
    }
}
.grid--vflush {
    @include grid-gutter-vertical(0);
}
.grid--hflush {
    @include grid-gutter-horizontal(0);
}
.grid--vloose {
    @include grid-gutter-vertical($grid-gutter-v--loose);
}
.grid--hloose {
    @include grid-gutter-horizontal($grid-gutter-h--loose);
}
.grid--vextra-loose {
    @include grid-gutter-vertical($grid-gutter-v--extra-loose);
}
.grid--hextra-loose {
    @include grid-gutter-horizontal($grid-gutter-h--extra-loose);
}
.grid--vtight {
    @include grid-gutter-vertical($grid-gutter-v--tight);
}
.grid--htight {
    @include grid-gutter-horizontal($grid-gutter-h--tight);
}
.grid--vsemi-tight {
    @include grid-gutter-vertical($grid-gutter-v--semi-tight);
}
.grid--hsemi-tight {
    @include grid-gutter-horizontal($grid-gutter-h--semi-tight);
}
.grid--max-width {
    max-width: 1400px;
    margin: 0 auto;
}

.grid--max-width-tight {
    max-width: 990px;
    margin: 0 auto;
}

.grid--flex {
    align-items: stretch; // too presumptuous
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    direction: ltr; // let flex-direction deal with this.

    &.grid--left {
        justify-content: flex-start;
    }
    &.grid--center {
        justify-content: center;
    }
    &.grid--right {
        justify-content: flex-end;
    }
    &.grid--reverse {
        flex-direction: row-reverse;
    }
    // These might be superfluous.
    // Maybe flex should always stretch?
    &.grid--top {
        align-items: flex-start;
    }
    &.grid--middle {
        align-items: center;
    }
    &.grid--bottom {
        align-items: flex-end;
    }
    &.grid--mobile-reverse {
        @include breakpoint(tablet) {
            flex-direction: column-reverse;
        }
    }

    > * {
        display: flex;
        flex: none;
        flex-direction: column;

        &.grid__item--stretch {
            align-self: stretch;

            > *:only-child {
                flex-grow: 0;
            }
        }

        &.grid__item--start {
            align-self: start;
        }

        &.grid__item--end {
            align-self: end;
        }

        &.grid__item--center {
            align-self: center;
        }

        // When background styling is on a child element,
        // stretch it up to fill the parent.
        > *:only-child {
            flex: 1 0 auto;
        }
        // Images should never stretch
        > img:only-child {
            flex: none;
        }

        > .account-notice:only-child {
            flex: none;
        }
    }
}

    .grid-item {
        position: relative;

        &--border {

            &:after {
                width: 1px;
                height: 100%;
                background-color: rgba($c-account-border, 0.3);
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                transform: translateX(calc((#{$grid-gutter-h} / 2) - 1px));
            }

        }
    }

.modern-grid {
    display: grid;
}

.modern-grid--two-col {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 10px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
    }

    .l-full {
        grid-column: span 2;

        @include breakpoint(tablet) {
            grid-column: span 1;
        }
    }

    .l-one-half {
        grid-column: span 1;
        width: 100%;
    }
}
