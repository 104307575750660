.basket-link {
    position: relative;
}

    .basket-link__link {
        position: relative;
        
        @include breakpoint(tablet) {
            display: flex;
        }

        @include breakpoint(tablet) {
            display: flex;
        }

        svg {
            fill: $c-grey--dark;

            @include breakpoint(tablet) {
                height: 18px;
                width: 22px;
            }

            .header--clear:not(:hover) & {
                fill: $c-white;
            }
        }
    }

        .basket-link__text {
            @include lightondark;
            @include font(12px, 15px);

            position: absolute;
            bottom: 50%;
            left: 50%;
            padding: 2px 7px;
            color: $c-white;
            background: $c-black;
            border-radius: 10px;
        }

            .basket-link__text--hidden {
                display: none;
            }
