.section {
    padding: $grid-gutter-v 0;
    background: $c-section-bg;
    overflow-x: visible;

    @include breakpoint(mobile) {
        padding: $grid-gutter-v--tight 0;
    }
}

.section--overflow-hidden {
    overflow: hidden;
}

.section--alt {
    background: $c-section-bg--alt;
}

.section--search-results {
    background: $search-results;
}

.section--altdark {
    background: $grey2;
}

.section--altdarker {
    background: $grey4;
}

.section--altdarkest {
    color: $c-white;
    background: $c-grey--mid;
}

.section--blue {
    background: $c-brand;
}

.section--blue-fade {
    background: linear-gradient(180deg, $c-brand 0%, rgba($c-brand, 0.8) 100%);
}

.section--highlight {
    background: $c-section-bg--highlight;
    color: white;
}

.section--gridfix {
    overflow: hidden;
}

.section--visible {
    overflow: visible;
}

.section--title-padding {
    padding: 30px 0 50px;
}

.section--medium-padding {
    padding: 50px 0;

    @include breakpoint(tablet) {
        padding: 30px 0;
    }

    @include breakpoint(mobile) {
        padding: 20px 0;
    }
}

.section--large-padding {
    padding: 60px 0;

    @include breakpoint(tablet) {
        padding: 45px 0;
    }

    @include breakpoint(mobile) {
        padding: 30px 0;
    }
}

.section--large-padding-bottom {
    padding-bottom: 60px;

    @include breakpoint(tablet) {
        padding-bottom: 45px;
    }

    @include breakpoint(mobile) {
        padding-bottom: 30px;
    }
}

.section--tight {
    padding: $grid-gutter-h--tight 0;
}

.section--medium {
    padding: 20px 0;
}

.section--flush {
    padding: 0;
}

.section--flush-top {
    padding-top: 0;
}

.section--flush-bottom {
    padding-bottom: 0;
}

.section--small-bottom {
    padding-bottom: 30px;
}

.section--divider {
    border-bottom: 1px solid $c-section-border;

    &.section--alt {
        border-color: $c-section-border--alt;
    }

    &.section--highlight {
        border-color: $c-section-border--highlight;
    }
}

.section--white-divider {
    border-bottom: 1px solid white;
}

.section--centered {
    text-align: center;
}

.section--portal-header {
    padding: 15px 0;
    color: $c-white;
    background: $grey5;

    @include breakpoint(mobile) {
        padding: 15px 0;
    }
}

.section--product-guide {
    background-image: url(/images/product-guide-sketch.jpg);
    background-position: center center;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(/images/product-guide-sketch--2x.jpg);
    }
}

    .section__head {
        padding-top: 30px;
        padding-bottom: 30px;

        @include breakpoint(mobile) {
            padding-bottom: 20px;
        }
    }

    .section__head--flush-top {
        padding-top: 0;
    }

    .section__head--flush-bottom {
        padding-top: 0;
    }

    .section__head--border-bottom {
        border-bottom: 1px solid $c-section-border;
    }

        .section__title {
            @include rem(32px);

            line-height: calc(40 / 32);
            margin-bottom: 10px;

            @include breakpoint(mobile) {
                @include rem(24px);

                line-height: calc(29 / 24);
            }

            &:last-child {
                margin-bottom: 0;

                @include breakpoint(mobile) {
                    margin-bottom: 10px;
                }
            }
        }

        .section__title--space-below {
            margin-bottom: 20px;
        }

        .section__intro {
            color: $footer-grey;

            @include breakpoint(mobile) {
                margin-bottom: 10px;
            }

            p {
                @include f-light;
                @include rem(16px);

                line-height: calc(24 / 16);

                @include breakpoint(mobile) {
                    @include rem(12px);

                    line-height: calc(20 / 12);
                }
            }
        }

.section--overlap {
    position: relative;
    z-index: 1;
}

.section--cta {
    @include rem(25px);
    font-weight: 400;
}

    .section--cta .section__button {
        margin-left: 20px;

        @include breakpoint(tablet) {
            margin-left: 0;
        }
    }

    .section--cta .section__text {
        display: inline-block;

        @include breakpoint(tablet) {
            display: block;
            margin-bottom: 20px;
        }
    }

.section-nav {
    text-align: center;
}

    .section-nav__link {
        @include rem(14px);
        display: inline-block;
        padding: 32px 15px 32px;
        color: $c-grey--mid;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            color: $c-black;
        }
    }

    .heading3.section__title {
        margin-bottom: 20px;
    }

.section--sticky-basket {
    position: fixed; // fallback for ie11
    position: sticky;
    width: 100%;
    bottom: 0;
    border-top: 1px solid $c-border;
    z-index: 2;

    @include breakpoint(phablet) {
        padding: 20px 0;
    }
}

