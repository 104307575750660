.career-departments {
    @include breakpoint(tablet) {
        padding: 0;
    }
}

    .career-departments__department {
        padding: 30px 0;
        display: block;
        border-right: 1px solid $background-grey;
        text-align: center;

        &:hover {
            .career-departments__department__title,
            .career-departments__department__footer {
                color: $c-black;
            }
        }

        @include breakpoint(tablet) {
            border-left: 1px solid $background-grey;
            border-right: 1px solid $background-grey;
            border-bottom: 1px solid $background-grey;
            padding: 15px 0;
        }

        @include breakpoint(mobile) {
            padding: 7px 0;
            @include rem(12px);
        }
    }

    .career-departments__department--first {
        border-left: 1px solid $background-grey;
    }

    .career-departments__department--empty {
        cursor: default;

        .career-departments__department__icon,
        .career-departments__department__title {
            opacity: 0.4;
        }

        &:hover {
            .career-departments__department__title,
            .career-departments__department__footer {
                color: $c-grey--mid;
            }
        }
    }

        .career-departments__department__icon {
            max-width: 60px;
            margin: 0 auto 20px;
        }

        .career-departments__department__title {
            text-transform: uppercase;
            min-height: 35px;

            @include breakpoint(mobile) {
                min-height: 30px;
            }
        }

        .career-departments__department__footer {
            padding-top: 10px;
            display: block;
            height: 16px;
            line-height: 16px;
        }

.vacancy-snippets {}

    .vacancy-snippets__title {
        margin-bottom: 55px;
        margin-top: 50px;

        @include breakpoint(tablet) {
            margin: 30px 0;
        }

        @include breakpoint(tablet) {
            margin: 20px 0;
        }
    }

        .vacancy-snippets__title--hidden {
            display: none;
        }

    .vacancy-snippets__start-text {
        padding-bottom: 30px;
    }

        .vacancy-snippets__start-text--hidden {
            display: none;
        }

.vacancy-snippet {
    padding-bottom: 50px;
}

    .vacancy-snippet--hidden {
        display: none;
    }

    .vacancy-snippet__title {
        @include linktitle;
        margin-bottom: 15px;
    }

    .vacancy-snippet__intro {
        margin-bottom: 10px;
    }

    .vacancy-snippet__meta {
        margin-bottom: 15px;
    }

.vacancy {}

    .vacancy__meta {
        margin-bottom: 50px;
        @include rem(16px);
    }

        .vacancy__meta__title {
            @include rem(18px);
        }

    .vacancy__section {
        margin-bottom: 40px;
    }

        .vacancy__section__title {
            margin-bottom: 35px;
        }

    .vacancy__footer {
        border-top: 1px solid $background-grey;
        padding: 40px 0;

        @include breakpoint(tablet) {
            text-align: center;
        }
    }

        .vacancy__footer__cta {
            float: right;

            @include breakpoint(tablet) {
                float: none;
                margin-bottom: 20px;
            }
        }

.no-vacancies {
    padding-bottom: 50px;
}

    .no-vacancies--hidden {
        display: none;
    }

.section--vacancy-wrap-hidden {
    display: none;
}
