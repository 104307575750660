.banner {
    text-align: center;

    .container {
        position: relative;
        overflow: visible;

        & > *:last-child {
            margin-bottom: 0;
        }
    }
}

.banner__title {
    @include rem(52px);
    font-weight: normal;
    line-height: 1*64/52;
    margin: 0 auto 15px;
    max-width: 800px;
    color: $c-black;

    @include breakpoint(tablet) {
        @include rem(40px);
        line-height: 1*64/40;
    }

    @include breakpoint(mobile) {
        @include rem(30px);
    }

    .banner__title__light {
        @include f-thin;
    }
    &--flush-bottom {
        margin-bottom: 0;
    }

    .banner--top-margin & {
        @include breakpoint(tablet) {
            margin-top: 20px;
        }
    }

    .banner--left-align & {
        text-align: left;
        margin-left: 85px;

        @include breakpoint(tablet) {
            margin-left: 20px;
        }

        @include breakpoint(mobile) {
            @include rem(24px); //this breaks away from site-wide banner value - is this okay? It's per design
        }
    }
}

.banner__title--small {
    @include subtitle;
    margin-bottom: 38px;

    @include breakpoint(tablet) {
        @include rem(35px);
        margin-bottom: 38px;
    }

    @include breakpoint(mobile) {
        @include rem(28px);
        margin-bottom: 38px;
    }
}

.banner__subtitle {
    @include rem(16px);
    @include f-light;
    line-height: 1*26/16;
    max-width: 550px;
    margin: 0 auto;
}
.banner__subtitle--alt {
    color: $c-grey--mid;
}

.banner__intro {
    @include rem(16px);
    @include f-light;
    max-width: 900px;
    margin: 0 auto;
}

.banner__backlink,
.banner__meta-link {
    @include rem(14px);
    display: block;
    position: absolute;
    top: -15px;
    left: 0;
    color: $c-grey--mid;
    text-transform: uppercase;

    span {
        border-bottom: 1px solid $c-grey--mid;
    }

    &:before {
        @extend .fa;
        content: "\f104";
        margin-right: 10px;
    }

    &:hover {
        color: black;
    }

    .section--highlight & {
        color: white;

        span {
            border-color: white;
        }
    }

    @include breakpoint(tablet) {
        position: static;
        margin: 15px auto;
    }
}

    .banner__backlink--standalone {
        display: block;
        position: static;
        text-align: left;
    }

    .banner__backlink--flush {
        margin: 0;
    }

.banner__warnings {
    text-align: left;
}

.banner__warnings p {
    @include copy;
    @include rem(16px);
    @include f-regular;
    color: $c-error;
    margin-bottom: 0;

    a {
        color: $c-error;
        text-decoration: underline;
    }

    &:before {
        @extend .fa;
        content: '\f071';
        margin-right: 10px;
    }
}

.banner__warning {
    @include rem(16px);
    display: block;
    position: absolute;
    bottom: -10px;
    right: 0;
    color: $c-error;

    @include breakpoint(mobile) {
        position: static;
        margin: 0 auto;
    }
}

// Gallery type selector

.banner__nav {
    @include rem(14px);
    position: absolute;
    right: 0;
    bottom: 0;

    label {
        display: inline-block;
        margin-right: 10px;
    }

    @include breakpoint(tablet) {
        text-align: center;
        position: static;
    }
}

    .banner__nav--top {
        top: -10px;
        bottom: auto;
    }
