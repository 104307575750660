.account-user-form {
}

    .account-user-form__projects {
        border: 2px solid $light-grey;
        padding: 12px;
        margin-bottom: 14px;

        .field:last-child {
            margin-bottom: 0;
        }

        .check_box__text {
            color: inherit;
        }
    }

        .account-user-form__projects-title {
            @include font(16px, 22px);
            color: $c-black;
            margin-bottom: 12px;
        }
